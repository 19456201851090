import { Injectable } from "@angular/core";
import { ApiService } from "../api.service";

@Injectable()
export class PaymentService {
  private apiPrefix = '/client/payments';

  constructor(
    private api: ApiService,
  ) {
  }

  createPayment() {
    return this.api.post(`${this.apiPrefix}/web`, {});
  }

  createSubscription() {
    return this.api.post(`${this.apiPrefix}/subscription`, {});
  }

  checkPayment(payId: string) {
    return this.api.patch(`${this.apiPrefix}/web`, {payId});
  }

  getCurrentSubscription() {
    return this.api.get(`${this.apiPrefix}/subscription`);
  }

  cancelSubscription() {
    return this.api.delete(`${this.apiPrefix}/subscription`);
  }

  changeSubscriptionPaymentInfo() {
    return this.api.patch(`${this.apiPrefix}/subscription/credit-card`, {});
  }

  getSubscriptionTransferDetails() {
    return this.api.get(`${this.apiPrefix}/subscription/transfer-details`);
  }

  createSubscriptionTransferCheckout() {
    return this.api.post(`${this.apiPrefix}/subscription/transfer-checkout`, {});
  }

  createEarlySaleWebPayment() {
    return this.api.post(`${this.apiPrefix}/web/early-sale`, {});
  }
}
